@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500);
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  min-height: 100%;
  height: 100%;
}
#root {
  min-height: 100%;
  height: 100%;
}
.app {
    height: 100%;
}
#homepage {
  min-height: 100%;
}
.nav-custom {
    height: 100px;
    background-color: #202020;
    color: #757575;
    box-shadow: 0 8px 8px -5px #757575;
}
.navbar-container{
    display: flex;
    align-items: center;
}


.list-item {
    text-decoration: none;
    color: white;
    padding: 0 .5em;
    list-style: none;
    font-size: 20px;
    font-weight: 600;
}
.list-item:hover {
    text-decoration: none;
    color:  #38D1d1;
    cursor: pointer;
}
.navbar-collapse {
    background: transparent;

}

#nav-toggler {
    border: 1px solid  #38D1d1 !important;
    background-color:  #38D1d1;
}
#nav-span i {
    margin-top: 5px;
    color: white;
}
.resume-link {
    color: white;
}
.resume-link:hover {
    color: #38D1d1;
    text-decoration: none;
}

/* homepage nav styles */
.homepage-nav {
    width: 100%;
    box-shadow: none;
    color: white;
}

#homepage-brand {
    font-weight: 700;
    font-family: 'Yellowtail', cursive;
    font-size: 45px;
    color: white;
    background-color: #D13838;
    padding: 5px 15px 5px 5px;
    border-radius: 5px;
}

@media screen and (max-width: 767px){
    .navbar-collapse {
        background: #202020;
        border: 1px solid  #D13838;
        padding: 10px;
    }
    .collapse-landing {
        background-color: rgba(0, 120, 255, 0.5);
        border: none;

    }
}
#landing {
    height: 100vh;
    width: 100%;
    background-color: #202020;
    font-family: 'Roboto Condensed', sans-serif;
}
.landing-content {
    width: 50%;
    margin-left: 15%;
    height: 75%;
    position: absolute;
    display: flex;
    align-items: center;
    color: white;
    text-align: center;
    z-index: 1000;
}
.landing-text {
    color: white;
    position: absolute;
    text-align: start;
}

.landing-h1 {
    font-size: 80px;
    margin: 0 0 10px 0;
    font-weight: 500;
}
.landing-p {
    margin: 0;
    font-size: 18px;
    margin-bottom: 25px;
    color: #B8B7B7;    
}
.landing-contact {
    display: flex;
    justify-content: space-between;
}
.landing-contact-info {
    color: #D13838;
    margin: 0;
}
.landing-link {
    color: white;
}
.landing-link:hover {
    color: #D13838;
    text-decoration: none;
}

@media screen and (max-width: 1300px) {
    .landing-h1 {
        font-size: 70px;
    }
    .landing-p {
        font-size: 16px;
    }
    .landing-contact {
        font-size: 14px;
    }
}
@media screen and (max-width: 1100px) {
    .landing-content {
        width: 60%;
    }

}
@media screen and (max-width: 900px) {
    .landing-content {
        margin-left: 10%;
        width: 70%;
    }
    .landing-h1 {
        font-size: 55px;
    }
    .landing-p {
        font-size: 14px;
    }
}
@media screen and (max-width: 600px) {
    .landing-content {
        margin-left: 5%;
        width: 90%;
    }
    /* .landing-h1 {
        font-size: 50px;
    } */
}
@media screen and (max-width: 470px) {
    .landing-h1 {
        font-size: 48px;
    }
}
@media screen and (max-width: 360px) {
    .landing-contact {
        display: inline;
        text-align: center;
    }
}
@media screeen and (max-width: 550px) {
    .shapes li:nth-child(1){
        left: 10%;
        width: 600px;
        height: 600px;
        -webkit-animation-delay: 0s;
                animation-delay: 0s;
        background-color: #38D1d1;
    }
    
}

.area-for-animation {
    height: 100%;
}
.shapes{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;    
    overflow: hidden;
    margin: 100px 0 0 0;
    padding: 0;
}

.shapes li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background-color: #5F5F5F;
    -webkit-animation: animate 25s linear infinite;
            animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.shapes li:nth-child(1){
    left: 10%;
    width: 80px;
    height: 80px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    background-color: #D13838;
}


.shapes li:nth-child(2){
    left: 20%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 12s;
            animation-duration: 12s;
    background-color: #38D1d1;
}

.shapes li:nth-child(3){
    left: 30%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 4s;
            animation-delay: 4s;
}

.shapes li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-duration: 18s;
            animation-duration: 18s;
    background-color:  #38d1d1 ;
}

.shapes li:nth-child(5){
    left: 50%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    background-color:#D13838;
}

.shapes li:nth-child(6){
    left: 60%;
    width: 110px;
    height: 110px;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
}

.shapes li:nth-child(7){
    left: 70%;
    width: 150px;
    height: 150px;
    -webkit-animation-delay: 7s;
            animation-delay: 7s;
    background-color: #38D1D1 ;
}

.shapes li:nth-child(8){
    left: 80%;
    width: 25px;
    height: 25px;
    -webkit-animation-delay: 15s;
            animation-delay: 15s;
    -webkit-animation-duration: 45s;
            animation-duration: 45s;
    background-color: #D13838;
}

.shapes li:nth-child(9){
    left: 80%;
    width: 15px;
    height: 15px;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 35s;
            animation-duration: 35s;
}

.shapes li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-duration: 11s;
            animation-duration: 11s;
    background-color: #D13838;
}



@-webkit-keyframes animate {

    0%{
        -webkit-transform: translateY(0) rotate(0deg);
                transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        -webkit-transform: translateY(-1000px) rotate(720deg);
                transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}



@keyframes animate {

    0%{
        -webkit-transform: translateY(0) rotate(0deg);
                transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        -webkit-transform: translateY(-1000px) rotate(720deg);
                transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}
.social-links {
    display: flex;
    align-items: center;
}
.social-link {
    color: white;
    margin-left: 20px;
    font-size: 28px;
}
.social-link:hover {
    color: #38D1d1;
}
 @media screen and (max-width: 360px) {
     .social-links {
         justify-content: center;
     }
 }
#projects {
    background-color: #191919;
}
.projects-row {
    width: 80%;
    margin-left: 10%;
    padding: 50px 0 100px 0;
    justify-content: space-between;
}
.projects-12 {
    color: white;
    display: flex;
    font-size: 40px;
    padding: 40px 0 20px 0;
}
.projects-12 p {
    margin: 0 0 0 15px;
    color: #d13838;
    font-weight: 800;
}
.projects-col {
    margin: 0;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    background-size: 100% 100%;
    margin-bottom: 20px;
    /* height: 300px; */
    max-width: 49%;
    overflow: hidden;
    border-radius: 10px;
    aspect-ratio: 3 / 1.7;
}
.projects-hover {
    display: none;
}
.show-info {
    display: inline;
    min-height: 100%;
    min-width: 110%;
    padding: 10px 5% 0 5%;
    /* background-color: rgba(22,22,22, 0.98); */
    color: white;
    background-color: rgba(32, 32, 32, 0.98);
}
@media screen and (max-width: 992px) {
    .projects-col {
        max-width: 100%;
    }
}
@media screen and (max-width: 454px) {
    .projects-row {
        width: 90%;
        margin-left: 5%;
    }
}
@media screen and (max-width: 356px) {
    .projects-row {
        width: 96%;
        margin-left: 4%;
    }
    .projects-col {
        aspect-ratio: 3/2.2;
    }
}

.project-hov-top {
    display: flex;
    justify-content: space-between;
    height: 100%;
}
.project-name {
    font-size: 20px;
}
.project-blue {
    color: #38D1d1;

}
.project-red {
    color: #D13838;

}
.project-info {
    font-size: 14px;
}
.tech-used {
    display: block;
    padding: 0 10px 0 0px;
}
.tech-used p {
    font-size: 20px;
}

.icon-div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    text-align: center;
}
.icon-div p {
    font-size: 15px;
    margin: 5px 0 0 0;
    color: white;
}

@media screen and (max-width: 1120px) {
    .icon-div {
        max-height: 30px;
    }
    .icon-div p {
        font-size: 13px;
    }

}
@media screen and (max-width: 992px) {
    .icon-div {
        max-height: 100%;
    }
    .icon-div p {
        font-size: 16px;
    }
}
@media screen and (max-width: 610px) {
    .tech-used-p {
        margin-bottom: 5px;
    }
    .icon-div {
        max-height: 30px;
        text-align: center;
    }
}
@media screen and (max-width: 390px){
    .tech-used-p {
        font-size: 14px;
    }
}
.project-hov-bottom {
    bottom: 0;
    position: absolute;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
}
.custom-button {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
    margin-right: 10px;
    padding: 0;
    height: 40px;
}
.custom-button a {
    color: white;
    padding: 10px 15px 10px 15px;
    margin: 0;
}
.custom-button a:hover {
    text-decoration: none;
}
.button-project-blue:hover {
    border: 1px solid  #38D1d1;
}
.button-project-blue a:hover {
    color: #38D1d1;
}
.button-project-red:hover {
    border: 1px solid  #D13838;
}
.button-project-red a:hover {
    color:  #D13838;
}
.project-icon {
    margin-left: 5px;
}


@media screen and (max-width: 1200px){
    .custom-button a {
        padding: 10px;
    }
}

@media screen and (max-width: 1120px) {
    .custom-button a {
        font-size: 14px;
    }

}
@media screen and (max-width: 992px) {
    .custom-button a {
        font-size: 16px;
    }
}
@media screen and (max-width: 515px) {
    .project-icon {
        display: none;
    }
}
@media screen and (max-width: 418px) {
    .custom-button a {
        padding: 3px;
    }
    .custom-button {
        height: 30px;
    }
    .custom-button a {
        font-size: 13px;
    }
}
.footer {
    height: 110px;
    background-color: #202020;
}
.footer-row {
    width: 80%;
    margin-left: 10%;
    justify-content: space-between;
    padding-top: 30px;
}
.footer-info {
    color: #d13838;
    font-size: 18px;
}
.footer-links-div {
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 532px) {
    .footer-info {
        font-size: 16px;
    }
    .footer-row {
        width: 90%;
        margin-left: 5%;
    }
}
@media screen and (max-width: 424px) {
    .footer-info {
        font-size: 14px;
    }
    .footer-row {
        width: 96%;
        margin-left: 2%;
    }
}

.resume-page {
    height: 100%;
}
.resume-iframe {
    width: 100%;
    height: 100%;
}
.not-found { 
  background-color: #202020; 
  color: #fff;
  font-size: 100%;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  height: 100%;
}

.not-found-button {
  font-weight: 300;
  color: #fff;
  font-size: 1.2em;
  text-decoration: none;
  border: 1px solid #efefef;
  padding: .5em;
  border-radius: 3px;
  float: left;
  margin: 6em 0 0 -155px;
  left: 50%;
  position: relative;
  transition: all .3s linear;
}

.not-found-button:hover {
  color: #fff;
}

.not-found-p {
  font-size: 2em;
  text-align: center;
  font-weight: 100;
}

.four-o-four {
  text-align: center;
  font-size: 15em;
  font-weight: 100;
  margin: 0;
  text-shadow: #0D0D0D 1px 1px, #0D0D0D 2px 2px, #0D0D0D 3px 3px, #141414 4px 4px, #141414 5px 5px, #141414 6px 6px, #141414 7px 7px, #141414 8px 8px, #141414 9px 9px, #141414 10px 10px, #141414 11px 11px, #141414 12px 12px, #141414 13px 13px, #141414 14px 14px, #141414 15px 15px, #141414 16px 16px, #141414 17px 17px;
}

