#landing {
    height: 100vh;
    width: 100%;
    background-color: #202020;
    font-family: 'Roboto Condensed', sans-serif;
}
.landing-content {
    width: 50%;
    margin-left: 15%;
    height: 75%;
    position: absolute;
    display: flex;
    align-items: center;
    color: white;
    text-align: center;
    z-index: 1000;
}
.landing-text {
    color: white;
    position: absolute;
    text-align: start;
}

.landing-h1 {
    font-size: 80px;
    margin: 0 0 10px 0;
    font-weight: 500;
}
.landing-p {
    margin: 0;
    font-size: 18px;
    margin-bottom: 25px;
    color: #B8B7B7;    
}
.landing-contact {
    display: flex;
    justify-content: space-between;
}
.landing-contact-info {
    color: #D13838;
    margin: 0;
}
.landing-link {
    color: white;
}
.landing-link:hover {
    color: #D13838;
    text-decoration: none;
}

@media screen and (max-width: 1300px) {
    .landing-h1 {
        font-size: 70px;
    }
    .landing-p {
        font-size: 16px;
    }
    .landing-contact {
        font-size: 14px;
    }
}
@media screen and (max-width: 1100px) {
    .landing-content {
        width: 60%;
    }

}
@media screen and (max-width: 900px) {
    .landing-content {
        margin-left: 10%;
        width: 70%;
    }
    .landing-h1 {
        font-size: 55px;
    }
    .landing-p {
        font-size: 14px;
    }
}
@media screen and (max-width: 600px) {
    .landing-content {
        margin-left: 5%;
        width: 90%;
    }
    /* .landing-h1 {
        font-size: 50px;
    } */
}
@media screen and (max-width: 470px) {
    .landing-h1 {
        font-size: 48px;
    }
}
@media screen and (max-width: 360px) {
    .landing-contact {
        display: inline;
        text-align: center;
    }
}
@media screeen and (max-width: 550px) {
    .shapes li:nth-child(1){
        left: 10%;
        width: 600px;
        height: 600px;
        animation-delay: 0s;
        background-color: #38D1d1;
    }
    
}

.area-for-animation {
    height: 100%;
}
.shapes{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;    
    overflow: hidden;
    margin: 100px 0 0 0;
    padding: 0;
}

.shapes li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background-color: #5F5F5F;
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.shapes li:nth-child(1){
    left: 10%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
    background-color: #D13838;
}


.shapes li:nth-child(2){
    left: 20%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
    background-color: #38D1d1;
}

.shapes li:nth-child(3){
    left: 30%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.shapes li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
    background-color:  #38d1d1 ;
}

.shapes li:nth-child(5){
    left: 50%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
    background-color:#D13838;
}

.shapes li:nth-child(6){
    left: 60%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.shapes li:nth-child(7){
    left: 70%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
    background-color: #38D1D1 ;
}

.shapes li:nth-child(8){
    left: 80%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
    background-color: #D13838;
}

.shapes li:nth-child(9){
    left: 80%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.shapes li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
    background-color: #D13838;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}