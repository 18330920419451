@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500);

.not-found { 
  background-color: #202020; 
  color: #fff;
  font-size: 100%;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  height: 100%;
}

.not-found-button {
  font-weight: 300;
  color: #fff;
  font-size: 1.2em;
  text-decoration: none;
  border: 1px solid #efefef;
  padding: .5em;
  border-radius: 3px;
  float: left;
  margin: 6em 0 0 -155px;
  left: 50%;
  position: relative;
  transition: all .3s linear;
}

.not-found-button:hover {
  color: #fff;
}

.not-found-p {
  font-size: 2em;
  text-align: center;
  font-weight: 100;
}

.four-o-four {
  text-align: center;
  font-size: 15em;
  font-weight: 100;
  margin: 0;
  text-shadow: #0D0D0D 1px 1px, #0D0D0D 2px 2px, #0D0D0D 3px 3px, #141414 4px 4px, #141414 5px 5px, #141414 6px 6px, #141414 7px 7px, #141414 8px 8px, #141414 9px 9px, #141414 10px 10px, #141414 11px 11px, #141414 12px 12px, #141414 13px 13px, #141414 14px 14px, #141414 15px 15px, #141414 16px 16px, #141414 17px 17px;
}
