.footer {
    height: 110px;
    background-color: #202020;
}
.footer-row {
    width: 80%;
    margin-left: 10%;
    justify-content: space-between;
    padding-top: 30px;
}
.footer-info {
    color: #d13838;
    font-size: 18px;
}
.footer-links-div {
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 532px) {
    .footer-info {
        font-size: 16px;
    }
    .footer-row {
        width: 90%;
        margin-left: 5%;
    }
}
@media screen and (max-width: 424px) {
    .footer-info {
        font-size: 14px;
    }
    .footer-row {
        width: 96%;
        margin-left: 2%;
    }
}
