.nav-custom {
    height: 100px;
    background-color: #202020;
    color: #757575;
    box-shadow: 0 8px 8px -5px #757575;
}
.navbar-container{
    display: flex;
    align-items: center;
}


.list-item {
    text-decoration: none;
    color: white;
    padding: 0 .5em;
    list-style: none;
    font-size: 20px;
    font-weight: 600;
}
.list-item:hover {
    text-decoration: none;
    color:  #38D1d1;
    cursor: pointer;
}
.navbar-collapse {
    background: transparent;

}

#nav-toggler {
    border: 1px solid  #38D1d1 !important;
    background-color:  #38D1d1;
}
#nav-span i {
    margin-top: 5px;
    color: white;
}
.resume-link {
    color: white;
}
.resume-link:hover {
    color: #38D1d1;
    text-decoration: none;
}

/* homepage nav styles */
.homepage-nav {
    width: 100%;
    box-shadow: none;
    color: white;
}

#homepage-brand {
    font-weight: 700;
    font-family: 'Yellowtail', cursive;
    font-size: 45px;
    color: white;
    background-color: #D13838;
    padding: 5px 15px 5px 5px;
    border-radius: 5px;
}

@media screen and (max-width: 767px){
    .navbar-collapse {
        background: #202020;
        border: 1px solid  #D13838;
        padding: 10px;
    }
    .collapse-landing {
        background-color: rgba(0, 120, 255, 0.5);
        border: none;

    }
}