.project-hov-bottom {
    bottom: 0;
    position: absolute;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
}
.custom-button {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
    margin-right: 10px;
    padding: 0;
    height: 40px;
}
.custom-button a {
    color: white;
    padding: 10px 15px 10px 15px;
    margin: 0;
}
.custom-button a:hover {
    text-decoration: none;
}
.button-project-blue:hover {
    border: 1px solid  #38D1d1;
}
.button-project-blue a:hover {
    color: #38D1d1;
}
.button-project-red:hover {
    border: 1px solid  #D13838;
}
.button-project-red a:hover {
    color:  #D13838;
}
.project-icon {
    margin-left: 5px;
}


@media screen and (max-width: 1200px){
    .custom-button a {
        padding: 10px;
    }
}

@media screen and (max-width: 1120px) {
    .custom-button a {
        font-size: 14px;
    }

}
@media screen and (max-width: 992px) {
    .custom-button a {
        font-size: 16px;
    }
}
@media screen and (max-width: 515px) {
    .project-icon {
        display: none;
    }
}
@media screen and (max-width: 418px) {
    .custom-button a {
        padding: 3px;
    }
    .custom-button {
        height: 30px;
    }
    .custom-button a {
        font-size: 13px;
    }
}