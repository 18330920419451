#projects {
    background-color: #191919;
}
.projects-row {
    width: 80%;
    margin-left: 10%;
    padding: 50px 0 100px 0;
    justify-content: space-between;
}
.projects-12 {
    color: white;
    display: flex;
    font-size: 40px;
    padding: 40px 0 20px 0;
}
.projects-12 p {
    margin: 0 0 0 15px;
    color: #d13838;
    font-weight: 800;
}
.projects-col {
    margin: 0;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    background-size: 100% 100%;
    margin-bottom: 20px;
    /* height: 300px; */
    max-width: 49%;
    overflow: hidden;
    border-radius: 10px;
    aspect-ratio: 3 / 1.7;
}
.projects-hover {
    display: none;
}
.show-info {
    display: inline;
    min-height: 100%;
    min-width: 110%;
    padding: 10px 5% 0 5%;
    /* background-color: rgba(22,22,22, 0.98); */
    color: white;
    background-color: rgba(32, 32, 32, 0.98);
}
@media screen and (max-width: 992px) {
    .projects-col {
        max-width: 100%;
    }
}
@media screen and (max-width: 454px) {
    .projects-row {
        width: 90%;
        margin-left: 5%;
    }
}
@media screen and (max-width: 356px) {
    .projects-row {
        width: 96%;
        margin-left: 4%;
    }
    .projects-col {
        aspect-ratio: 3/2.2;
    }
}
